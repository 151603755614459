import React, { useState, useEffect, useContext } from 'react'
//import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import axios from 'axios';
import { RiFileExcel2Line } from 'react-icons/ri'
import { Url } from './Url';
import ContextEstados from '../context/Estados';
import TablaEquipos from './tables/TablaEquipos';
import TablaEquiposQR from './tables/TablaEquiposQR';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa'

const ListadoEquipos = () => {
    const [lineasExcel, setLineasExcel] = useState([])
    const [equiposSearch, setEquiposSearch] = useState([])
    const { equipos,
        setEquipos,
        idEquipo,
        idUsuario,
        buscarEquipo,
        equipoSelected,
        setEquipoSelected,
        setTipoModal,
        setOpenVista,
        roles,
        viewQR
    } = useContext(ContextEstados);


    useEffect(() => {
        const consultaEquipos = async () => {
            //console.log("idusuario ",idUsuario)
            const formData = new FormData()
            formData.append('busqueda', '')
            formData.append('idusuario', idUsuario)
            try {
                const response = await axios({
                    url: Url + 'api/equiposListRep.php',
                    method: 'POST',
                    data: formData,
                })
                //console.log("equipos ", response)
                setEquipos(response.data.results.equipos);
                setLineasExcel(response.data.results.equiposExcel);
            } catch (e) {
                console.log(e)
            }
        }
        consultaEquipos()
    }, [idUsuario, idEquipo])


    useEffect(() => {
        if (equipos) {
            if (buscarEquipo !== '') {
                let rData = []
                equipos.forEach(element => {
                    if (element.codigo.search(new RegExp(buscarEquipo, 'i')) !== -1 || element.descripcion.search(new RegExp(buscarEquipo, 'i')) !== -1 || element.area.search(new RegExp(buscarEquipo, 'i')) !== -1) {
                        rData.push(element);
                    }
                });
                setEquiposSearch(rData)
            }
            else {
                setEquiposSearch(equipos)
            }
        }
    }, [equipos, buscarEquipo])

    const handleDownload = (data) => {
        const libro = XLSX.utils.book_new()
        const hoja = XLSX.utils.json_to_sheet(data)
        XLSX.utils.book_append_sheet(libro, hoja, "Equipos");
        setTimeout(() => {
            XLSX.writeFile(libro, "Equipos.xlsx");
        }, 1000);
    }

    const asignarEquipo = (id) => {
        let rData = [...equiposSearch];
        rData.forEach(element => {
            if (element.idcentral === id) {
                element.view = !element.view;
            }
        });
        setEquiposSearch(rData);
        equipos.forEach(element => {

            if (element.id === id) {
                setEquipoSelected(element)
                return
            }
        });
    }

    const verEquipo = async (id) => {
        asignarEquipo(id);
        setTipoModal(1)
        setOpenVista(true);
    }
    const editarEquipo = async (id) => {
        asignarEquipo(id);
        setTipoModal(2)
        setOpenVista(true);
    }
    const eliminarEquipo = async (id) => {
        asignarEquipo(id);
        setTipoModal(3)
        setOpenVista(true);
    }


    return (
        <>
            {typeof equipos !== 'undefined' && (
                <>
                    {!viewQR && (
                        <button
                            type='button'
                            className='mb-3 bg-green-600 hover:bg-green-400 shadow-lg p-3 rounded-md text-white uppercase flex'
                            onClick={() => handleDownload(lineasExcel)}
                        >
                            <p className='text-xl mr-2'>
                                <RiFileExcel2Line />
                            </p>
                            Exportar a Excel
                        </button>
                    )}
                    {!viewQR ? (
                        <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto mt-1'>
                            <thead>
                                <tr className='uppercase bg-blue-400 text-white'>
                                    <th className='border-gray-400 p-2'>
                                        Código
                                    </th>
                                    <th className='border-gray-400 p-2'>
                                        Nombre
                                    </th>
                                    <th className='border-gray-400 p-2'>
                                        Yacimiento
                                    </th>
                                    <th className='border-gray-400 p-2'>
                                        Ubicación
                                    </th>
                                    <th className='border-gray-400 p-2'>
                                        Tipo
                                    </th>
                                    <th className='border-gray-400 p-2'>
                                        Estado
                                    </th>
                                    <th className='border-gray-400 p-2'>
                                        Actividad
                                    </th>
                                    <th className='border-gray-400 p-2'>
                                        Acciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {equiposSearch.map((item, index) => (
                                    item.view && (
                                        <tr
                                            key={item.id}
                                            className={`${equipoSelected.id === item.id ? item.subequipos.length > 0 ? 'font-bold bg-blue-200' : 'bg-blue-200' : equipoSelected.id === item.idcentral ? 'bg-blue-100' : item.subequipos.length > 0 ? 'font-bold bg-blue-50' : item.subequipos.length === 0 ? 'bg-white' : index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'} ' hover:cursor-pointer border-b border-gray-200 ')`}
                                            onClick={() => asignarEquipo(item.id)}
                                        >
                                            <td className={`border-gray-400 p-1 pl-3 ${equipoSelected.id === item.idcentral && 'pl-5'}`}>{item.codigo}</td>
                                            <td className='border-gray-400 p-1'>{`${item.descripcion} ${item.codigo}`}</td>
                                            <td className='border-gray-400 p-1 text-center'>{item.area}</td>
                                            <td className='border-gray-400 p-1 text-center'>{item.nombreubicacion}</td>
                                            <td className='border-gray-400 p-1 text-center uppercase'>{item.nombretipo}</td>
                                            <td className='border-gray-400 p-1 text-center'><p className={`${item.idestado === '1' ? 'bg-green-300' : item.idestado === '2' ? 'bg-red-300' : item.idestado === '3' ? 'bg-orange-300' : 'bg-red-300'} ' p-2 rounded-md '`}>{item.estadoequipo}</p></td>
                                            <td className='border-gray-400 p-1 text-center'><p className={`${item.actividad && 'bg-orange-300 p-1 rounded-lg'}`}>{item.actividad}</p></td>
                                            <td className='p-1 flex justify-center'>
                                                <button
                                                    className='bg-indigo-500 p-3 rounded-md text-white'
                                                    onClick={() => verEquipo(item.id)}
                                                >
                                                    <FaEye />
                                                </button>
                                                {(roles.includes("1") || roles.includes("11")) && (
                                                    <>
                                                        <button
                                                            className='bg-gray-400 p-3 rounded-md text-white ml-3'
                                                            onClick={() => editarEquipo(item.id)}
                                                        >
                                                            <FaPen />
                                                        </button>
                                                        <button
                                                            className='bg-red-400 p-3 rounded-md text-white ml-3'
                                                            onClick={() => eliminarEquipo(item.id)}
                                                        >
                                                            <FaTrash />
                                                        </button>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <TablaEquiposQR />
                    )}
                </>)}
        </>
    );
};

export default ListadoEquipos