import { useState, useEffect, useContext } from 'react'
import ContextEstados from '../context/Estados';
import QRCode from "react-qr-code";
import axios from 'axios';
import { Url } from './Url'
import VEquipoActividades from './VEquipoActividades';

const FViewEquipo = () => {
    const [equipoEstados, setEquipoEstados] = useState([])
    const { equipos, setOpenVista, equipoSelected, setEquipoSelected } = useContext(ContextEstados);

    
    useEffect(() => {
        const getEquipoEstados = async () => {
            const formData=new FormData()
            formData.append('idequipo',equipoSelected.id)
            try{
                const response = await axios({
                    url: Url+'api/getEquipoEstados.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setEquipoEstados(response.data.results)
            } catch (e) {
                console.log(e)
            }        
        }
        getEquipoEstados()
    }, [])

    const asignarEquipo = (id) => {
        equipos.forEach(element => {
            if (element.id === id) {
                setEquipoSelected(element)
                return
            }
        })
    }

    return (
        <div className='mt-10 mb-10 px-10'>
            <div className='w-full flex'>
                <div className='sm:w-3/4'>
                    <p className='text-xl text-gray-600'><span className='font-semibold'>Código: </span>{equipoSelected.codigo}</p>
                    <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Nombre: </span>{equipoSelected.descripcion}</p>
                    <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Ubicación: </span>{`${equipoSelected.area} - ${equipoSelected.nombreubicacion}`}</p>
                    <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Tipo: </span>{equipoSelected.nombretipo}</p>
                </div>
                <div className='sm:w-1/4'>
                    <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={equipoSelected.id}
                        viewBox={`0 0 256 256`}
                    />
                </div>
            </div>
            <p className={`p-2 mt-4 mb-8 rounded-md text-xl text-center ${equipoSelected.idestado === '1' ? 'bg-green-300' : equipoSelected.idestado === '2' ? 'bg-red-300' : equipoSelected.idestado === '3' ? 'bg-orange-300' : 'bg-red-300'}`}>{equipoSelected.estadoequipo}</p>
            { typeof equipoEstados.activo!=='undefined' && (
                equipoEstados.activo!==0 && (
                    <p className='w-1/3 mx-auto text-center font-bold bg-green-300 p-1 mt-1 rounded-xl'>{`${equipoEstados.nActivo} ${parseInt(equipoEstados.activo)*24} Horas`}</p>
                )                   
            )}
            { typeof equipoEstados.reserva!=='undefined' && (
                equipoEstados.reserva!==0 && (
                    <p className='w-1/3 mx-auto text-center font-bold bg-red-300 p-1 mt-1 rounded-xl'>{`${equipoEstados.nReserva} ${parseInt(equipoEstados.reserva)*24} Horas`}</p>
                )                   
            )}
            { typeof equipoEstados.mantenimiento!=='undefined' && (
                equipoEstados.mantenimiento!==0 && (
                    <p className='w-1/3 mx-auto text-center font-bold bg-orange-300 p-1 mt-1 rounded-xl'>{`${equipoEstados.nMantenimiento} ${parseInt(equipoEstados.mantenimiento)*24} Horas`}</p>
                )                   
            )}
            { typeof equipoEstados.fueraservicio!=='undefined' && (
                equipoEstados.fueraservicio!==0 && (
                    <p className='w-1/3 mx-auto text-center font-bold bg-red-300 p-1 mt-1 rounded-xl'>{`${equipoEstados.nFueraservicio} ${parseInt(equipoEstados.fueraservicio)*24} Horas`}</p>
                )                   
            )}
            <VEquipoActividades />
            {equipoSelected.idcentral !== '0' && (
                <div className='my-5 w-full bg-orange-200 p-3 rounded-xl text-gray-600'>
                    <h3 className='text-center text-2xl uppercase font-semibold'>Equipo Central o Equipo Maestro</h3>
                    <button type='button' onClick={() => asignarEquipo(equipoSelected.idcentral)}>
                        <p className='mt-2 text-xl hover:cursor-pointer'>{`${equipoSelected.codigocentral} - ${equipoSelected.descripcioncentral}`}</p>
                    </button>
                </div>
            )}
            {
                equipoSelected.subequipos.length > 0 && (
                    <h3 className='text-center text-2xl uppercase mt-5 bg-blue-400 text-white'>Sub Equipos</h3>
                )
            }
            {
                equipoSelected.subequipos && equipoSelected.subequipos.map((item, index) =>
                (
                    <div key={index} className='w-full'>
                        <p
                            className={`text-xl text-gray-600 uppercase p-1 hover:cursor-pointer ${index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}
                            onClick={() => asignarEquipo(item.id)}
                        >
                            {`${item.codigo} - ${item.descripcion}`}
                        </p>
                    </div>
                ))
            }
            <div className='w-full flex mt-10'>
                {equipoSelected.imagenes && equipoSelected.imagenes.map((item, index) =>
                (
                    <div key={index} className='w-full'>
                        <img className='w-full object-cover rounded-xl' src={`${Url}${item.imagen}`} />
                    </div>
                )
                )}
            </div>
            {equipoSelected.observaciones && (
                <div className='my-5 w-full bg-cyan-100 p-3 rounded text-gray-600'>
                    <h3 className='text-center text-2xl uppercase font-semibold'>Observaciones</h3>
                    <p className='mt-2 text-xl hover:cursor-pointer'>{equipoSelected.observaciones}</p>
                </div>
            )
            }
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FViewEquipo